import { GET_SPECS_DATA, GET_SPECS_DATA_ONE } from '../types';

const initilaState = {
  specsData: [],
  specsOneData: [],
  giftData: [],
};

const specsReducer = (state = initilaState, action) => {
  switch (action.type) {
    case GET_SPECS_DATA:
      return {
        ...state,
        specsData: action.payload,
        giftData: action.payload.gift[0],
      };
    case GET_SPECS_DATA_ONE:
      return {
        ...state,
        specsOneData: action.payload,
        giftData: action.payload.gift[0],
      };
    default:
      return state;
  }
};

export default specsReducer;
