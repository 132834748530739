import React, { useState } from 'react';
const Test = () => {
  const [image, setImage] = useState([]);
  const onSubmit = () => {};
  const handlePhoto = (e) => {
    setImage(e);
    console.log(image);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          name="photo"
          onChange={handlePhoto}
        />
        <input type="submit" />
      </form>
    </>
  );
};

export default Test;
