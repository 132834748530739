import axios from 'axios';
import {
  GET_SPECS_DATA,
  GET_USER_DATA,
  CREATE_DATA_FAILURE,
  GET_SPECS_DATA_ONE,
} from '../types';

export const createUserData = (data) => (dispatch) => {
  axios
    .post('https://lc2-kback.onrender.com/user/create', data)
    .then((res) => {
      console.log('res', res);
    })
    .catch((err) => {
      if (err.response.status === 403) {
        dispatch({
          type: CREATE_DATA_FAILURE,
          payload: "Oups, vous avez déjà participé !",
        });
      }
    });
};

export const getData = () => (dispatch) => {
  axios
    .get('https://lc2-kback.onrender.com/specs/read')
    .then((res) => {
      console.log(res);
      dispatch({
        type: GET_SPECS_DATA,
        payload: res.data[0],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUserData = (param, navigate) => (dispatch) => {
  axios
    .get(`https://lc2-kback.onrender.com/user/read/${param}`)
    .then((res) => {
      dispatch({
        type: GET_USER_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      navigate('/404');
      console.log(err);
    });
};

export const getOneData = (name, navigate) => (dispatch) => {
  axios
    .get(`https://lc2-kback.onrender.com/specs/readOne/${name}`)
    .then((res) => {
      dispatch({
        type: GET_SPECS_DATA_ONE,
        payload: res.data,
      });
    })
    .catch((err) => {
      navigate('/404');
      console.log(err);
    });
};
