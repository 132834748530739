import React, { useEffect, useRef, useState } from 'react';
import { getOneData } from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Input,
  Checkbox,
  Modal,
  Button,
  Form,
  notification,
  Avatar,
} from 'antd';
import { Wheel } from 'react-custom-roulette';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import {
  Bs1CircleFill,
  Bs2CircleFill,
  Bs3CircleFill,
  BsBoxArrowUpRight,
} from 'react-icons/bs';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import { Hourglass } from 'react-loader-spinner';
import Marquee from 'react-fast-marquee';
import CryptoJS from 'crypto-js';
import 'react-phone-input-2/lib/style.css';

const Dashboard = () => {
  const [form] = Form.useForm();
  const param = useParams().name;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const { width } = useWindowSize();
  const specsData = useSelector((state) => state.specsData.specsOneData);
  const giftData = useSelector((state) => state.specsData.giftData);
  const error = useSelector((state) => state.userData.error);
  const lotteryRef = useRef(null);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [winningOption, setWinningOption] = useState('');
  const [show, setShow] = useState(false);
  const [gift, setGift] = useState([]);
  const [firtModal, setFirstModal] = useState(true);
  const [validationModal, setValidationModal] = useState(false);
  const [store, setStore] = useState(false);
  const [nationality, setNationality] = useState('');

  const colors = [
    '#FFFFFF',
    '#CD2F2B',
    '#FFFFFF',
    '#CD2F2B',
    '#99c2ff',
    '#00FFFF',
  ];
  const [data, setData] = useState([
    {
      option: '1 year of pokes',
      style: { backgroundColor: '#B4853C', textColor: 'white' },
    },
    {
      option: 'A dessert',
      style: { backgroundColor: '#EFC392', textColor: 'white' },
    },
    {
      option: 'A beverage',
      style: { backgroundColor: '#B4853C', textColor: 'white' },
    },
    {
      option: 'A meal',
      style: { backgroundColor: '#EFC392', textColor: 'white' },
    },
  ]);

  const scrollToLottery = () => {
    lotteryRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (Object.entries(giftData).length > 0) {
      setGift(giftData);
    }
  }, [giftData]);

  useEffect(() => {
    dispatch(getOneData(param, navigate));
  }, [dispatch, param, navigate]);

  useEffect(() => {
    if (Object.entries(gift).length > 0) {
      setData(
        Object.keys(gift)
          .filter((key) => key.includes('award'))
          .map((key) => gift[key])
          ?.map((award, index) => ({
            option: award,
            style: {
              backgroundColor: colors[index % colors.length],
              textColor: 'black',
            },
          }))
      );
    }
  }, [gift]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setValidationModal(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, [validationModal]);

  const encryptParam = (param) => {
    const secretKey = 'baby shark';
    return CryptoJS.AES.encrypt(param, secretKey).toString();
  };

  const getWeightedRandomIndex = (odds) => {
    const totalWeight = odds.reduce((acc, weight) => acc + weight, 0);
    const threshold = Math.random() * totalWeight;

    let weightSum = 0;
    for (let i = 0; i < odds.length; i++) {
      weightSum += odds[i];
      if (weightSum >= threshold) {
        return i;
      }
    }
    return -1;
  };

  const handleSpinClick = () => {
    const newPrizeNumber = getWeightedRandomIndex(
      Object.keys(specsData.gift[0])
        .filter((key) => key.includes('odds'))
        .map((key) => parseFloat(specsData.gift[0][key]))
    );
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const handleSpinComplete = () => {
    console.log(data);
    if (prizeNumber >= 0 && prizeNumber < data.length) {
      const competition = param;
      const communication = specsData.communication;
      const winningPrize = data[prizeNumber].option;
      const userData = form.getFieldValue();
      const link = `prom0te.fr/${param}`;
      userData.winningPrize = winningPrize;
      userData.store = store;
      userData.nationality = nationality;
      userData.link = link;
      userData.competitions = competition;
      userData.communication = communication;
      axios
        .post(`https://lc2-kback.onrender.com/user/create/`, userData)
        .then((res) => {
          console.log(res);
          setWinningOption(winningPrize);
          showModal();
          setShow(true);
          setMustSpin(false);
          setTimeout(() => {
            setShow(false);
          }, 10000);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 500) {
            setShow(false);
            setMustSpin(false);
            api.warning({
              message: "Oups, vous avez déjà participé !",
              description:
                'Participation refusée, vous avez déjà participé à ce concours.',
            });
          }
        });
    }
  };

  const showModal = () => {
    api.success({
      message: (
        <div className="font-AnekLatin text-[20px] font-bold">
          Félicitations vous avez gagné 🎉 Vous allez recevoir un SMS !
        </div>
      ),
      description: (
        <div className="font-AnekLatin text-[20px] font-bold">
          {/*  {specsData.confirmateion}!  */}
        </div>
      ),
    });
  };

  const handleFirst = () => {
    setFirstModal(false);
    setValidationModal(true);
  };
  const onFinish = () => {
    handleSpinClick();
  };
  const onChange = (e) => {
    setStore(e.target.checked);
  };

  return (
    <>
        {/*
      <header className="absolute w-full top-10 z-30 flex justify-start sm:block 6sm:hidden">
        <div className="flex gap-2 logText items-center ml-4">
          <Avatar
            src={specsData.logSrc}
            className="w-10 h-10 cursor-pointer"
            alt=""
          />
          {specsData.competitions}
        </div>
      </header>
       */}
      <section
        className="relative"
        style={{ backgroundColor: `${specsData.mainColor}` }}
      >
        {contextHolder}
        <div className="flex">
          <div
            className="flex flex-col w-screen lg:h-[656px] md:h-[500px] sm:h-[450px] 6sm:[274px] py-8 bg-cover bg-no-repeat justify-center 2xl:gap-60 xl:gap-60 lg:gap-56 md:gap-28 sm:gap-20"
            style={{ backgroundImage: `url(${specsData.imageSrc})` }}
          >
            <div className="flex flex-col gap-3 justify-center items-center">
              <div
                className="font-AnekLatin lg:text-[50px] md:text-[40px] sm:text-[30px] 6sm:text-[25px] font-extrabold sm:px-12 6sm:px-4 text-center"
                style={{ color: `${specsData.textColor}` }}
              >
                {specsData.title}
              </div>
              <div
                className="font-AnekLatin lg:text-[25px] md:text-[18px] sm:[15px] 6sm:text-[12px] font-bold text-center"
                style={{ color: `${specsData.textColor}` }}
              >
                {specsData.subtitle}
              </div>
              <div className="mt-4">
                <button
                  className="px-6 py-4 w-40 hover:shadow-2xl rounded-2xl font-AnekLatin font-bold"
                  style={{
                    backgroundColor: `${specsData.buttonColor}`,
                    color: `${specsData.butColor}`,
                  }}
                  onClick={scrollToLottery}
                >
                  Je veux jouer !
                </button>
              </div>
            </div>
          </div>
          <Modal
            title={[
              <div className="flex justify-center" key="title_key">
                <Avatar
                  src={specsData.logSrc}
                  className="w-10 h-10 cursor-pointer"
                  alt=""
                />
              </div>,
            ]}
            open={firtModal}
            onOk={handleFirst}
            onCancel={() => { }}
            closable={false}
            maskClosable={false}
            footer={[
              <div className="flex justify-center mt-12 mx-6" key="won_footer">
                <a
                  className="flex px-4 py-3 hover:shadow-2xl w-full justify-center font-AnekLatin font-bold"
                  style={{
                    background: `${specsData.buttonColor}`,
                    color: `${specsData.butColor}`,
                  }}
                  onClick={handleFirst}
                  href={
                    specsData.link
                      ? specsData.link
                      : 'https://www.tripadvisor.com/'
                  }
                  target="blank"
                >
                  {specsData.actionWindowButtonText}
                </a>
              </div>,
            ]}
            key="first"
          >
            <div className="flex justify-center font-AnekLatin font-[700] text-2xl text-center">
              {specsData.actionWindowTitle}
            </div>
            <div className="flex flex-col font-AnekLatin text-[14px] mt-6 gap-4 pl-10 font-[600]">
              <div className="flex items-center gap-3">
                <div>
                  <Bs1CircleFill size={25} />
                </div>
                <div>Vous serez redirigé vers notre page Google</div>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <Bs2CircleFill size={25} />
                </div>
                <div>Laissez-nous un avis ⭐</div>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <Bs3CircleFill size={25} />
                </div>
                <div>Revenez sur cet onglet et profitez de vos cadeaux ! 🎁</div>
              </div>
            </div>
          </Modal>
          <Modal
            title={[
              <div
                className="flex justify-center font-AnekLatin font-bold text-2xl text-center"
                key="validation"
              >
                Soutenez-nous en laissant un commentaire 💖
              </div>,
            ]}
            open={validationModal}
            onOk={handleFirst}
            onCancel={() => { }}
            closable={false}
            maskClosable={false}
            footer={[
              <div
                className="flex justify-center mt-6 mx-8"
                key="validation_footer"
              >
                <a
                  className="flex px-4 py-3 hover:shadow-2xl border items-center w-full justify-center text-white font-AnekLatin font-bold"
                  style={{
                    backgroundColor: `${specsData.buttonColor}`,
                    color: `${specsData.butColor}`,
                  }}
                  href={
                    specsData.link
                      ? specsData.link
                      : 'https://www.tripadvisor.com/'
                  }
                  target="blank"
                >
                  Je laisse mon avis&nbsp;&nbsp;&nbsp;
                  <BsBoxArrowUpRight />
                </a>
              </div>,
            ]}
            className="rounded-[20px]"
            key="validation"
          >
            <div className="flex justify-center mt-8">
              <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#262626', `${specsData.buttonColor}`, '#4d4dff']}
              />
            </div>
            <div className="flex justify-center mt-2">Chargement...</div>
            <div className="flex justify-center font-AnekLatin text-lg mt-8 font-bold">
              En attente de validation...
            </div>
          </Modal>
        </div>
        <Marquee speed={150} className="flex bg-[#F3F2F6]">
          <div className="flex py-4 font-bold font-AnekLatin text-2xl gap-2 w-[800px]">
            Jeu <div className="text-[#CD2F2B]"> 100%</div>Gratuit ! -
            Jeu <div className="text-[#CD2F2B]">100%</div> Gagnant !
          </div>
        </Marquee>
        <div
          ref={lotteryRef}
          className="grid items-center lg:grid-cols-2 6sm:grid-cols-1 gap-12 lg:my-16 6sm:my-4"
        >
          {show && <Confetti width={width} height={2000} timer={10} />}
          <div className="flex lg:justify-end 6sm:justify-center">
            <div className="flex flex-col sm:w-[440px] 6sm:w-80">
              <Form form={form} onFinish={onFinish} size="large">
                <div className="flex font-AnekLatin font-bold text-[30px] text-black lg:justify-start 6sm:justify-center 6sm:text-center">
                  {specsData.formButtonText}
                </div>
                <div className="flex font-AnekLatin font-normal text-[17px] text-black mt-1 lg:justify-start 6sm:justify-center 6sm:text-center">
                  {specsData.formSubtitle}
                </div>
                <div className="flex flex-col mt-6">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Veuillez saisir votre nom !',
                      },
                    ]}
                    name="name"
                  >
                    <Input
                      className="rounded-[5px] w-full"
                      allowClear
                      placeholder="Prénom"
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: 'Merci de saisir un numéro de téléphone valide',
                      },
                    ]}
                  >
                    <PhoneInput
                      country={'fr'}
                      style={{ height: '40px' }}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                      }}
                      onChange={(value, country) => {
                        form.setFieldsValue({ phone: value });
                        setNationality(country.countryCode);
                      }}
                    // value={this.state.phone}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        type: 'email',
                        message: 'Merci de saisir un email valide',
                      },
                      {
                        required: true,
                        message: 'Veuillez saisir votre adresse e-mail !',
                      },
                    ]}
                    name="email"
                  >
                    <Input
                      className="rounded-[5px]"
                      allowClear
                      placeholder="Email"
                    />
                  </Form.Item>
                </div>
                <div className="flex gap-2 font-AnekLatin font-normal text-black text-[15px] items-center">
                  <Checkbox onChange={onChange} />
                  {specsData.optionText}
                </div>
                <div className="flex mt-5">
                  <button
                    className="w-full font-AnekLatin text-center py-3 hover:shadow-2xl rounded-[16px]"
                    style={{
                      backgroundColor: `${specsData.buttonColor}`,
                      color: `${specsData.butColor}`,
                    }}
                    type="submit"
                  >
                    {specsData.formButtonText}
                  </button>
                </div>
              </Form>
            </div>
          </div>
          <div className="flex lg:justify-start 6sm:justify-center">
            <div className="relative">
              {data && (
                <Wheel
                  mustStartSpinning={mustSpin}
                  prizeNumber={prizeNumber}
                  data={data}
                  onStopSpinning={handleSpinComplete}
                  radiusLineColor={['tranparent']}
                  outerBorderColor={['#000000']}
                  outerBorderWidth={[12]}
                  spinDuration={[0.2]}
                  textDistance={65}
                  fontSize={[17]}
                  fontFamily="Times New Roman"
                  fontWeight="bold"
                />
              )}
              <div
                className="absolute w-28 h-28 rounded-full bg-cover bg-center z-10 top-[50%] left-[50%] border-4 border-[#000000] shadow-2xl"
                style={{
                  transform: 'translate(-50%, -50%)',
                  backgroundImage: `url(${specsData.logSrc})`,
                }}
              />
            </div>
          </div>
        </div>
        {/*  <Modal
          title={specsData.won}
          open={visible}
          onOk={handleOk}
          onCancel={() => {}}
          closable={false}
          maskClosable={false}
          footer={[
            <Button key="submit" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
          key="won"
        >
          <p className="font-bold text-[20px]">😇{winningOption}😇</p>
          <p className="mt-5">{specsData.confirmation}</p>
        </Modal> 
        
           <img
                  src="/assets/images/hand.svg"
                  className="w-[142px] h-[166px]"
                  alt=""
                />*/}
      </section>
    </>
  );
};

export default Dashboard;
