import { GET_USER_DATA, CREATE_DATA_FAILURE } from '../types';

const initilaState = {
  userData: [],
};

const userReducer = (state = initilaState, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case CREATE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
