import React from 'react';
import Layout from './components/Layout';
import Dashboard from './pages/';
import Test from './pages/test';
import NotFoundPage from './pages/404page';

const Routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/:name',
        element: <Dashboard />,
      },
    ],
  },
  {
    path: 'test',
    element: <Test />,
  },
  {
    path: '/404',
    element: <NotFoundPage />,
  },
];

export default Routes;
